/* CHAT STYLES */
* {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  letter-spacing: 0.5px;
}

.renderedMessages {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 45vh !important;
  width: 100% !important;
  flex-direction: column-reverse !important;
}

.ce-chat-list {
  background-color: rgb(240, 240, 240) !important;
}

.ce-chat-feed-column {
  max-width: 30% !important;
  flex: 0 0 30% !important;
}

.ce-chats-container {
  background-color: rgb(240, 240, 240) !important;
}

.ce-settings-column {
  max-width: 45% !important;
  width: 45% !important;
  flex: 0 0 45% !important;
}

.ce-person-text {
  padding-left: 60px !important;
  font-family: 'open sans';
  font-size: 16.5px !important;
}

.ce-person-container {
  background-color: #4b2aad !important;
  color: #fff;
  padding: 5px 12px 12px 12px !important;
}

.ce-person-title-container {
  background-color: #4b2aad !important;
  color: #fff;
  margin: -12px 0px -12px 0px;
}

#ce-add-username-input {
  border: 2px solid #4b2aad !important;
}

.ce-active-chat-card {
  background-color: #cabcdc !important;
  font-family: 'Open Sans' !important;

  color: #000000;
}

.ce-chat-subtitle-text {
  color: rgb(173, 171, 171) !important;
  font-family: 'Open sans' !important;
}

.ce-chat-title-text {
  font-family: 'Open sans' !important;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
}

.chat-title-big {
  font-weight: 300;
}

.ce-my-message-bubble {
  background-color: #4b2aad !important;
}

#ce-send-message-button {
  background-color: #4b2aad !important;
}

.ce-chat-title-container {
  color: #4b2aad !important;
}

.ce_message {
  font-family: 'Open sans !important';
}

.ce-chat-form-container {
  padding-bottom: 20px !important;
}

.ce-text-input {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
}

.ce-primary-button {
  border-radius: 6px !important;
  background-color: #4b2aad !important;
  position: relative;
  bottom: 1px;
}

.ce-danger-button {
  background-color: white !important;
  border-radius: 22px !important;
}

.ce-settings {
  background-color: rgb(240, 240, 240) !important;
}

.ce-autocomplete-input {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
}

.ce-autocomplete-options {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
  background-color: white !important;
}

.ce-chat-settings-container {
  padding-top: 12px !important;
}

.ce-chat-avatars-row {
  display: none !important;
}

/* CUSTOM FEED */

.chat-feed {
  height: 62vh;
  width: 100%;
  overflow: scroll;
}

.chat-title-container {
  width: calc(100% - 36px);

  text-align: center;
}

.chat-title {
  color: #7554a0;
  font-weight: 800;
  font-size: 24px;
}

.chat-subtitle {
  color: #7554a0;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
}

.message-block {
  width: 100%;
  display: inline-block;
}

.message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.message {
  padding: 6px 8px 6px 8px;
  font-size: 15px;
  border-radius: 6px;
  max-width: 60%;
  font-weight: 300;
}

.message-image {
  margin-right: 18px;
  object-fit: cover;
  border-radius: 6px;
  height: 30vw;
  /* width: 30vw; */
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.read-receipts {
  position: relative;
  bottom: 6px;
}

.read-receipt {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin: 1.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.message-form-container {
  bottom: 30px;
  width: 100% !important;
  background-color: rgb(240, 240, 240);
}

.message-form {
  overflow-y: hidden;
  border-radius: 6px;
  width: 100%;
  background-color: white;
}

.message-input {
  height: 40px;
  width: 80%;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  outline: none;
  font-size: 15px;
}

.image-button {
  cursor: pointer;
  padding: 0px 12px;
  height: 100%;
}

.send-button {
  height: 42px;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  cursor: pointer;
}

.send-icon {
  top: 1px;
  position: relative;
  transform: rotate(-90deg);
}

.picture-icon {
  top: 1px;
  position: relative;
  font-size: 14px;
}

/* FORM STYLES */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62, 5%;
}

.wrapper {
  height: 100vh;
  width: 100%;
  background: rgb(117, 84, 160);
  background: linear-gradient(
    90deg,
    rgba(117, 84, 160, 1) 7%,
    rgba(117, 84, 160, 1) 17%,
    rgba(106, 95, 168, 1) 29%,
    rgba(99, 103, 174, 1) 44%,
    rgba(87, 116, 184, 1) 66%,
    rgba(70, 135, 198, 1) 83%,
    rgba(44, 163, 219, 1) 96%,
    rgba(22, 188, 237, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  outline: none;
  margin-bottom: 25px;
}

.form {
  width: 400px;
}

.title {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;
}

.button {
  border-radius: 4px;
  border: none;
  background-color: white;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  padding: 20px;
  width: 200px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  width: 90%;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.gen-group-people::-webkit-scrollbar {
  display: none;
}

.gen-group-chat::-webkit-scrollbar {
  display: none;
}

.renderedMessages::-webkit-scrollbar {
  display: none;
}

.chat-feed::-webkit-scrollbar {
  display: none;
}

.gen-group-people .gen-group-chat .renderedMessages .chat-feed {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
