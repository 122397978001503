.react-multiple-carousel__arrow {
  z-index: inherit;
  background: rgba(255, 255, 255, 1);
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.react-multiple-carousel__arrow:hover {
  background: rgba(1, 126, 255 0.9);
}
.react-multiple-carousel__arrow::before {
  font-size: 25px;
  font-weight: bold;
  color: #7a8894;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}

.react-multiple-carousel__arrow--right:hover,
.react-multiple-carousel__arrow--left:hover {
  color: antiquewhite;
}
