body {
  box-sizing: border-box !important;
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  overflow-x: hidden !important;
}

.MuiDropzoneArea-root {
  min-height: 0px;
  background-color: brown;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assets.qbstudios.co.nz/fonts/Graphik-Regular-Web.eot')
      format('embedded-opentype'),
    url('https://assets.qbstudios.co.nz/fonts/Graphik-Regular-Web.woff')
      format('woff'),
    url('https://assets.qbstudios.co.nz/fonts/Graphik-Regular-Web.woff')
      format('woff2');
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.photo_zone {
  min-height: 50px;
}

.modalClass {
  z-index: 1000;
}

.modalOverlayClass {
  z-index: 999;
}
