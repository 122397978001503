.categoryLinkTag {
  border: none;
  border-radius: 35px;
  background: rgba(75, 42, 173, 1) !important;
  color: white;
  cursor: pointer;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  font-family: Open Sans !important;
  font-weight: 400 !important;
}

.categoryLinkTag:hover {
  border: none;
  border-radius: 35px;

  background: rgba(53, 30, 122) !important;
  color: white;
  cursor: pointer;
}

.whiteCategoryLinkTag {
  border: none;
  border-radius: 35px;
  background: white !important;
  color: rgba(75, 42, 173, 1) !important;
  cursor: pointer;
}

.categoriesHeader {
  color: rgba(75, 42, 173, 1) !important;
}

.cd {
  font-size: 115% !important;
  font-weight: 300 !important;
}

.choose {
  font-size: 138% !important;
}

.font-thin {
  font-weight: 100 !important;
  word-wrap: break-word;
}

.rounded-signup {
  border-radius: 35px !important;
}

.Cabin {
  font-family: Cabin !important;
}

.open-sans {
  font-family: Open Sans !important;
}

.topBanner {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url(https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1489163804-2880w.jpg) !important;
  width: 100vw !important;
  z-index: 3 !important;
}

.landingBannerWrapper {
  position: relative;
}

.landingBanner {
  min-height: 100% !important;
  width: 100vw !important;
  height: 120vh;
  position: relative;
}

#landingVideo {
  object-fit: cover;
  z-index: 2 !important;
  width: 100%;
  height: 100% !important;
  min-width: 100%;
  min-height: 100%;
}

img {
  object-fit: cover;
}

.mentors,
.rethink {
  color: white !important;
}

.landing-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.4) !important;
}

.landingBannerContent {
  position: relative;
  z-index: 5;
  color: white !important;
}

.square {
  width: 100px;
  height: 100px;
  background: red;
}

.rounded-input {
  border: none;
  line-height: 50px;
  border-radius: 25px;
  font-size: 120%;
  font-weight: 400 !important;
  background: #f3f3f3 !important;
}

.rounded-input:focus {
  border: none !important;
  border-radius: 25px !important;
}

.topBannerWrapper {
  position: relative;
}

.topBannerContent {
}

.topBannerContentsm {
  position: absolute;
  top: 60% !important;
}

.formWrapper {
  position: absolute !important;
  bottom: -63px;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  z-index: 10;
}

.formWrappersm {
  justify-content: center;
  display: flex;
  margin: 0;
  z-index: 10;
}

.formSubWrapper {
  border-radius: 20px !important;
}

.upskill,
.re-invent,
.articleText {
  color: rgb(53, 30, 122) !important;
}

.article-author {
  color: rgba(147, 147, 147, 1) !important;
  letter-spacing: 0.9px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.articleText {
  font-size: 100% !important;
}

.category-spacing {
  letter-spacing: 1px !important;
}

.explore-articles {
  font-size: 16px !important;
  max-width: 98%;
}

.learning {
  /* font-size: 25px; */
  color: rgb(53, 30, 122) !important;
}

.font-small {
  font-weight: 400 !important;
}

.font-w-300 {
  font-weight: 300 !important;
}

.font-w-200 {
  font-weight: 200 !important;
}

.font-68 {
  font-size: 68 !important;
}

.font-72 {
  font-size: 72px;
}

.text-30 {
  font-size: 30px !important;
}

.middleBannerWrapper {
  height: 130vh !important;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
}

.middleBanner {
  background: rgba(0, 0, 0, 0.4);
}

.middleBannerContent {
  /* position: absolute;
  top: 50%; */
}

.join,
.trainer {
  color: white;
}

.upskill,
.re-invent {
  font-variant: bold;
  font-weight: 700;
}

.font-180 {
  font-size: 180%;
}

.nav-item {
  max-width: 140px !important;
}

.articleImage {
  border: none;
  border-radius: 15px !important;
}

.articleImage:hover {
  transform: scale(1.1) !important;
  border-radius: 15px !important;
  overflow: hidden !important;
}

.more-image:hover {
  transform: scale(1.1) !important;
}

.more-image-wrapper {
  overflow: hidden !important;
}

.more-header {
  font-size: 40px !important;
}

.artticleText {
  font-size: 90%;
}

@media (max-width: 400px) {
  .article-overflow-hidden {
    height: 300px;
    border-radius: 15px !important;
    overflow: hidden !important;
  }

  .topBanner {
    min-height: 110vh !important;
  }

  .landingBanner {
    min-height: 100% !important;
    width: 100vw !important;
    height: 100vh;
    position: relative;
  }

  .more-image,
  .more-image-wrapper {
    height: 250px !important;
  }

  .sticky-section {
    background-position: center;
    background-size: cover !important;
    background-attachment: scroll !important;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background: url(https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1835921389-1920w.jpg);
  }

  .helping {
    height: 100vh !important;
  }

  .career-card-content {
    height: 120px !important;
  }

  .career-top-banner-wrapper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 95vh !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg) !important;
  }

  .mentorHeroWrapper {
    height: 130vh;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
  }
}

@media (max-width: 768px) {
  .block-hidden {
    display: block !important;
  }

  .hidden-block {
    display: none !important;
  }
  .center-left {
    text-align: center !important;
  }
}
@media (min-width: 769px) {
  .block-hidden {
    display: none !important;
  }
  .center-left {
    text-align: left !important;
  }

  .hidden-block {
    display: block !important;
  }
}

@media (min-width: 401px) and (max-width: 639px) {
  .article-overflow-hidden {
    height: 300px;
    border-radius: 15px !important;
    overflow: hidden !important;
  }

  .topBanner {
    min-height: 110vh !important;
  }

  .landingBanner {
    min-height: 100% !important;
    width: 100vw !important;
    height: 100vh;
    position: relative;
  }

  .more-image,
  .more-image-wrapper {
    height: 250px !important;
  }

  .sticky-section {
    background-position: center;
    background-size: cover !important;
    background-attachment: scroll !important;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background: url(https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1835921389-1920w.jpg);
  }

  .helping {
    height: 100vh !important;
  }

  .career-card-content {
    height: 120px !important;
  }

  .career-top-banner-wrapper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 160vh !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg) !important;
  }

  .mentorHeroWrapper {
    height: 160vh;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .article-overflow-hidden {
    height: 300px;
    border-radius: 15px !important;
    overflow: hidden !important;
  }

  .topBanner {
    min-height: 800px !important;
  }

  .landingBanner {
    min-height: 100% !important;
    width: 100vw !important;
    height: 145vh;
    position: relative;
  }

  .more-image,
  .more-image-wrapper {
    height: 240px !important;
  }

  .sticky-section {
    background-position: center;
    background-size: cover !important;
    background-attachment: fixed !important;
    height: 160vh;
    width: 100%;
    background-repeat: repeat;
    background: url(https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1835921389-1920w.jpg);
  }

  .helping {
    height: 160vh !important;
  }

  .career-card-content {
    height: 150px !important;
  }

  .career-top-banner-wrapper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    /* height:  !important; */
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg) !important;
  }

  .mentorHeroWrapper {
    height: 160vh;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
  }

  .headerMethodsWrapper {
    position: absolute;
    top: 90%;
  }
}

.headerWrapper {
  position: relative;
}

@media (min-width: 769px) {
  .article-overflow-hidden {
    height: 300px;
    border-radius: 15px !important;
    overflow: hidden !important;
  }

  .topBanner {
    min-height: 120vh !important;
  }

  .landingBanner {
    min-height: 100% !important;
    width: 100vw !important;
    height: 125vh;
    position: relative;
  }

  .more-image,
  .more-image-wrapper {
    height: 280px !important;
  }

  .sticky-section {
    background-position: center;
    background-size: cover !important;
    background-attachment: fixed !important;
    height: 180vh;
    width: 100%;
    background-repeat: no-repeat;
    background: url(https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1835921389-1920w.jpg);
  }

  .helping {
    height: 180vh !important;
  }

  .career-card-content {
    height: 200px !important;
  }

  .career-top-banner-wrapper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    /* height: 680px !important; */
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg) !important;
  }

  .mentorHeroWrapper {
    height: 145vh;
    position: relative;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
  }

  .headerMethodsWrapper {
    position: absolute;
    top: 78%;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.contact-us {
  background: rgba(75, 42, 173, 1) !important;
  border-radius: 30px;
  cursor: pointer !important;
}

.contact-us:hover {
  background: rgba(53, 30, 122, 1) !important;
}

.footer {
  min-height: 100vh;
  background: rgba(53, 30, 122, 1) !important;
}

.navbarHeight {
  overflow: hidden !important;
  height: 200px !important;
}

.dropDownItem:hover {
  background: rgba(53, 30, 122, 1) !important;
  cursor: pointer;
}

.dropDown {
  position: absolute !important;
  top: 60px !important;
  left: 200px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.career-transparent {
  background-color: rgba(0, 0, 0, 0.2) !important;
  height: 100%;
  width: 100vw;
}

.sticky-content {
  height: 160vh !important;
  z-index: 2;
}

.sticky-image {
  z-index: -1;
}

.career-top-banner-content {
  position: absolute;
  top: 35% !important;
  font-family: '' !important;
  font-weight: 300 !important;
}

.career-card {
  height: 300px;
}

.plansHeader {
  color: rgba(75, 42, 173, 1) !important;
}

.methods-header,
.methods-description {
  color: rgba(53, 30, 122, 1) !important;
}

.sessionBanner {
  background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.more-card-description,
.more-card-header {
  font-size: 104% !important;
}

.bg-more-background {
  background: rgba(53, 30, 122, 1);
}

/* .mentorHeroWrapper {
  height: 150vh;
  position: relative;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-image: url(https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg) !important;
} */

.mentorHero {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.eventsWrapper {
  background: rgba(26, 26, 26, 1) !important;
}

.event-title {
  font-size: 30px;
  font-family: 'Old Standard TT' !important;
  font-weight: bold;
}

.event-button {
  font-size: 16px !important;
}

.gray-scale-100 {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%) !important;
}

.gray-scale-100:hover {
  -webkit-filter: grayscale(100%);
  filter: grayscale(0%) !important;
}

.font-size-80 {
  font-size: 80% !important;
}

.poppins {
  font-family: Poppins !important;
}

.work-sans {
  font-family: Work Sans !important;
}

.qoute {
  color: rgba(255, 192, 0, 1) !important;
}
